@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/Open_Sans/OpenSans-VariableFont_wdth\,wght.ttf') format('truetype');
    font-weight: 100 900; /* This allows variable weights */
    font-style: normal;
}

.home-container {
    text-align: center;
    padding-top: 40px;
}

.home-logo {
    width: 300px; /* Increase the size here */
    height: auto;
    margin-bottom: 20px;
}

h1 {
    font-family: 'Open Sans';
    font-size: 2.5rem;
    color: #000000;
}
