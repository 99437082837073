.home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: White; /* Light grey background */
}

.board-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 1fr);
    width: 400px; /* Adjust size as needed */
    height: 400px;
    border: 2px solid #000; /* Black border around the board */
}

.row {
    display: contents; /* This ensures grid layout from parent */
}

.cell {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: #000; /* Black text for pieces */
}

.row:nth-child(odd) .cell:nth-child(even),
.row:nth-child(even) .cell:nth-child(odd) {
    background-color: #d18b47; /* Dark brown squares */
}

.row:nth-child(odd) .cell:nth-child(odd),
.row:nth-child(even) .cell:nth-child(even) {
    background-color: #ffce9e; /* Light brown squares */
}

