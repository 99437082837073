/* Navbar.css */
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/Open_Sans/OpenSans-VariableFont_wdth\,wght.ttf') format('truetype');
    font-weight: 100 900; /* This allows variable weights */
    font-style: normal;
}
/* Navbar general styling */
.navbar {
    display: flex;
    justify-content: flex-start; /* Align content to the left */
    align-items: center; /* Vertically center the logo and links */
    padding: 20px;
    background-color: #f0f0f5;
}

/* Logo container and logo */
.logo-container {
    margin-right: 20px; /* Space between logo and links */
}

.logo {
    width: 60px; /* Adjust size as needed */
    height: auto;
}

/* Navigation links */
.nav-links {
    display: flex;
    list-style: none;
    align-items: center;
    margin: 0;
    padding: 0;
}

.nav-links li {
    margin-right: 30px; /* Space between links */
}

.nav-links a {
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    color: #000000;
    font-size: 18px;
    transition: color 0.3s;
}

.nav-links a:hover {
    color: #004AAD; /* Hover color */
}
